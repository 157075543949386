<template>
  <div class="next-feature-container">
    <h1>NFT1 is dropping support for the explorer</h1>
    <p>
      Due to the lack of resources and the need to focus on other projects, we decided to stop supporting the explorer.
    </p>
    <p>
      We are in the process of open sourcing the codebase and we will provide a guide on how to run your own explorer.
    </p>
    <p>
      If you are interested in hosting and building on top of the explorer, please reach out to us on Discord or
      Twitter!
    </p>
  </div>
</template>

<script>
import { useRepository } from '../composables/useRepository'

export default {
  name: 'NextFeature',

  data: () => ({
    features: [],
  }),

  async created() {
    try {
      const repository = useRepository()
      this.features = await repository.getLatestFeatures()
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.next-feature-container {
  background-color: var(--grey-one);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 40px;
  padding-bottom: 80px;
}

.features-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  margin-top: 20px;
}

@media screen and (max-width: 1132px) {
  .next-feature-container {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
</style>
